.sider{
    position: absolute;
    height: 100%;
    width: 150px;
    background-color: rgba(25, 30, 38, 0.8);

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 30px;
        .box{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            .icon{
                font-size: 35px;
                color: #fff;
                justify-content: center;
            }
            .iconHover{
                font-size: 25px;
                color: #FFD700;
                justify-content: center;
            }
            .title{
                margin-top: 10px;
                color: #fff;
                justify-content: center;
            }
        }
        .box:hover{
            .icon{
                transform: scale(1.2);
                color: #fff;
            }
            .iconHover{
                transform: scale(1.2);
                color: yellow;
            }
        }
        .box:first-child{
            margin-top: 5px;
        }
    }


}
