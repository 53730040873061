.shopping{
    height:100%;
    overflow-y: scroll;
}

.exchangeCard{
    margin-top: 20px;
    margin-left: 20px;
    width: 200px;
    height: 270px;
    background: rgb(128, 128, 161);
    background: linear-gradient(344deg, rgba(40,40,55,1) 0%, rgb(91, 91, 105) 50%);
    box-shadow: 4px 6px 14px #2F2F42;
    border-radius: 3px;
    color: #F9F9F9;
    padding: 16px;
    transition: all .3s;
}

.exchangeCard2{
    margin-top: 20px;
    margin-left: 20px;
    width: 200px;
    height: 345px;
    background: rgb(128, 128, 161);
    background: linear-gradient(344deg, rgba(40,40,55,1) 0%, rgb(91, 91, 105) 50%);
    box-shadow: 4px 6px 14px #2F2F42;
    border-radius: 3px;
    color: #F9F9F9;
    padding: 16px;
    transition: all .3s;
    position: relative;
    flex: 0 0 auto; /* 确保卡片不被压缩 */
}

.exchangeCardDisJust{
    width: 100%;
    display: flex;
    justify-content: center
}

.exchangeCardDisJustTop{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.exchangeCardText{
    font-size:16px;
    font-weight: bold;
}

.exchangeCardTextColor{
    font-size:16px;
    font-weight: bold;
    color:red;
}

.slotContainer{
    margin-top: 10px;
}

.exchangeBtn{
    border: none;
    width: 100px;
    height: 40px;
    border-radius: 15px;
    font-size: 18px;
    font-weight: bold;
    background-color: rgba(33, 33, 33);
    color:rgba(174, 0, 255);
    /*margin-top: 10px;*/
    top: 275px;
    position: absolute;
}

.exchangeBtn:hover{
    box-shadow: 0 0 10px #ae00ff, 0 0 25px #001eff, 0 0 50px #ae00ff;
    transition: 0.6s;
}

.shoppingTitle{
    color: #ffffff;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
}

.myStoreBtn{
    height:35px;
    width:90px;
    margin-left: 7px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(33, 33, 33);
    color:white
}

.myStoreBtnContainer{
    position: absolute;
    width: 200px;
    margin-left: -16px;
    height:50px;
    top:275px
}

.myStoreTextContainer{
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 200px; /* 确保这是你想要的位置 */
}

.ant-input-number-input-wrap .ant-input-number-input{
    background-color:rgba(26,26,26) ;
    color:white;
}



