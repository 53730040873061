.layout{
    height: 750px;
    Width: 1250px;
    position: absolute;
    overflow: hidden;
    display: flex;
}

.main{
    background-image: url('/public/images/login.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    Width: 100%;
    z-index: 1;
}

.main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 25, 40, 0.8);
    pointer-events: none;
    z-index: -1;
}

.header{
    height: 60px;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 0 !important;
}

.headerImg{
    margin-left: 20px;
}

.headerTitle{
    color: #0e3742;
    font-size: 30px;
    font-weight: 600;
    animation: animate 5s linear infinite;
    margin-left: 100px;
    /*text-shadow: 0 0 10px #03bcf4,*/
    /*0 0 20px #03bcf4,*/
    /*0 0 40px #03bcf4,*/
    /*0 0 80px #03bcf4;*/
}
@keyframes animate{
    /*#0e3742*/
    /*,21%,31%,51%,60%,80%,90%,92%*/
    0%,31%,60%,90%{
        color: #055c7e;
    }
    /*20%,30%,50%,60%,80%,90%,*/
    20%,50%,80%,100%{
        color: #ffffff;
        text-shadow: 0 0 10px #03bcf4,
                     0 0 20px #03bcf4,
                     0 0 40px #03bcf4,
                     0 0 80px #03bcf4;
    }
}


.cursor {
    display: inline-block; /* 确保光标是行内块元素 */
    font-size: 30px; /* 增大光标的字体大小 */
    animation: blink 0.7s step-end infinite; /* 添加闪烁动画 */
    margin-bottom: 5px;
}

@keyframes blink {
    0%, 100% {
        opacity: 1; /* 光标可见 */
    }
    50% {
        opacity: 0; /* 光标不可见 */
    }
}


.sider{
    min-width: 80px !important;
    max-width: 80px !important;
}
